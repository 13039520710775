import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription, combineLatest, iif, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { ECompanyLoginMode, ETenantType, PermissionsService } from '@zonar-ui/auth';
import { ICompanyMap, IDivision } from '@zonar-ui/auth/lib/models/company.model';
import { IUserProfile, IUserProfileRole } from '@zonar-ui/auth/lib/models/user-profile.model';

import { AUTH_PERMISSIONS } from '@environments/shared';
import { ENV } from '@environments/environment.provider';
import { CompanyService } from './company-store.service';
import { environment } from '@environments/environment';
import { IUserGroupPolicy } from '@zonar-ui/auth/lib/models/user-group-policy.model';

const adminPermPatternBuilder = (companyId: string) => `:${companyId}:::${AUTH_PERMISSIONS.MANAGE_ALERTS}`;

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  isCompanyAdmin$ = new ReplaySubject<boolean>(1);
  isPartialAdmin$ = new ReplaySubject<boolean>(1);
  isAnyAdmin$ = new ReplaySubject<boolean>(1);
  isManagedThresholds$ = new ReplaySubject<boolean>(1);

  private isAnyAdminSubscription: Subscription;

  constructor(@Inject(ENV) private env: any, 
  private _permissionsService: PermissionsService,
  private _companyService: CompanyService) {}

  initialize(companyId: string) {
    this._permissionsService
      .getIsPermissionsLoaded()
      .pipe(filter(isLoaded => isLoaded), switchMap(() => this._permissionsService.getIsZonarUser()), 
          switchMap((isZonarAdmin) => (isZonarAdmin ? of(true) : this.checkIfCompanyAdmin$(companyId))))
      .subscribe((isCompanyAdmin) => {
        this.isCompanyAdmin$.next(isCompanyAdmin);
      });

    this._permissionsService
      .getIsPermissionsLoaded()
      .pipe(filter(isLoaded => isLoaded), switchMap(() => this._permissionsService.getIsZonarUser()), 
          switchMap((isZonarAdmin) => (isZonarAdmin ? of(true) : this.checkIfPartialAdmin$(companyId))))
      .subscribe((isPartialAdmin) => {
        this.isPartialAdmin$.next(isPartialAdmin);
      });

    this.isAnyAdminSubscription = combineLatest([this.isCompanyAdmin$, this.isPartialAdmin$]).subscribe(
        ([isCompanyAdmin, isPartialAdmin]) => {
          this.isAnyAdmin$.next(isCompanyAdmin || isPartialAdmin);
        }
      );

    this.getThresholdAdminProfiles(companyId)
      .pipe(take(1))
      .subscribe((data) => {
        this.isManagedThresholds$.next(data);
      });
  }

  /**
   * If the user is a company admin, then they need to match these criteria in order to be granted admin permissions:
   * - For group policy context:
   * 1. `roles` array in needs to contain object which has `id` property equal to the copmanyAdminRole parameter in the environment file
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   *
   * - For user policy context:
   * 1. `isAdminRole` value needs to be `true`
   * 2. `isLegacyDivisionsAll` value needs to be `true`
   * @param companyId the currently selected company on the UI
   */
  private checkIfCompanyAdmin$(companyId): Observable<boolean> {
    return this._permissionsService.getIsPermissionsLoaded().pipe(
      filter(isLoaded => isLoaded), // Ensure permissions are loaded before continuing
      switchMap(() => 
        this._getCompanyLoginMode().pipe(
          switchMap((loginMode) => {
            return iif(
              () => loginMode === ECompanyLoginMode.GROUP_POLICY,
              this._companyAdminGroupContextCheck(companyId),
              this._companyAdminUserContextCheck(companyId)
            );
          })
        )
      )
    );
  }

  private _companyAdminGroupContextCheck(companyId) {
    return this._permissionsService.getUserGroupPolicies().pipe(
      map((userPolicies) => {
        const allPoliciesForApplicationId = userPolicies.filter((userPolicy, i) => {
          return userPolicy.policy.grants.some((grant) => grant.application.id === environment.auth.applicationId);
        });
        const allPoliciesForCompanyId = allPoliciesForApplicationId.filter((userPolicy) => userPolicy.policy.companyId === companyId);
        const isAdminRolePresent = allPoliciesForCompanyId.some((policy) => {
          const grantForApp = policy.policy.grants.find((grant) => grant.application.id === environment.auth.applicationId);
          return grantForApp.roles.some((role) => role.id === environment.companyAdminRole);
        });
        return isAdminRolePresent;
      })
    );
  }

  private _partialAdminGroupContextCheck(companyId) {
    return this._permissionsService.getUserGroupPolicies().pipe(
      map((userPolicies) => {
        const allPoliciesForApplicationId = userPolicies.filter((userPolicy, i) => {
          return userPolicy.policy.grants.some((grant) => grant.application.id === environment.auth.applicationId);
        });
        const allPoliciesForCompanyId = allPoliciesForApplicationId.filter((userPolicy) => userPolicy.policy.companyId === companyId);
        const isAdminRolePresent = allPoliciesForCompanyId.some((policy) => {
          const grantForApp = policy.policy.grants.find((grant) => grant.application.id === environment.auth.applicationId);
          return grantForApp.roles.some((role) => role.id === environment.partialAdminRole);
        });
        return isAdminRolePresent;
      })
    );
  }

  private _companyAdminUserContextCheck(companyId) {
    return combineLatest([
      this._permissionsService.hasPermission(adminPermPatternBuilder(companyId)),
      this.isLegacyDivisionsAll(companyId)
    ]).pipe(
      map(([isAdminRole, isLegacyDivisionsAll]) => {
        return isAdminRole && isLegacyDivisionsAll;
      })
    );
  }

  private _partialAdminUserContextCheck(companyId) {
    return combineLatest([
      this._permissionsService.hasPermission(adminPermPatternBuilder(companyId)),
      this.isLegacyDivisionsSome(companyId)
    ]).pipe(
      map(([isAdminRole, isLegacyDivisionsSome]) => {
        return isAdminRole && isLegacyDivisionsSome;
      })
    );
  }

  /**
   * If the user is a partial company admin, then they need to match these criteria in order to be granted admin permissions:
   * - For group policy context:
   * 1. `roles` array in needs to contain object which has `id` property equal to the partialAdminRole parameter in the environment file
   * 2. `companyId` in user profile needs to exist and match the currently selected company in the UI
   *
   * - For user policy context:
   * 1. `isAdminRole` value needs to be `true`
   * 2. `isLegacyDivisionsSome` value needs to be `true`
   * @param companyId the currently selected company on the UI
   */
  private checkIfPartialAdmin$(companyId): Observable<boolean> {
    return this._permissionsService.getIsPermissionsLoaded().pipe(
      filter(isLoaded => isLoaded), // Ensure permissions are loaded before continuing
      switchMap(() => 
        this._getCompanyLoginMode().pipe(
          switchMap((loginMode) => {
            return iif(
              () => loginMode === ECompanyLoginMode.GROUP_POLICY,
              this._partialAdminGroupContextCheck(companyId),
              this._partialAdminUserContextCheck(companyId)
            );
          })
        )
      )
    );
  }  


  /**
   * Returns the company login mode for the currently selected company
   * @returns Observable of login mode for the currently selected company
   */
  private _getCompanyLoginMode(): Observable<ECompanyLoginMode> {
    return this._permissionsService.getIsPermissionsLoaded().pipe(
      filter(isLoaded => isLoaded),
      switchMap(() =>
        combineLatest([
          this._companyService.currentCompany$,
          this._permissionsService.getCompanyLoginMode()
        ])
      ),
      filter(([selectedCompany, companies]) => !!selectedCompany && !!companies),
      map(([selectedCompany, companies]) => {
        const company = companies.find((company) => selectedCompany.value === company.id);
        return company ? company.loginMode : ECompanyLoginMode.USER_PROFILE;
      })
    );
  }

  /**
   * Select current profile and check all divisions are LEGACY type
   *
   * @param companyId the currently selected company on the UI
   */
  private isLegacyDivisionsAll(companyId: string): Observable<boolean> {
    return combineLatest([this.getLegacyCompanyDivisionsByCompanyId(companyId), this.getProfileDivisions(companyId)]).pipe(
      map(([legacyCompanyDivisions, profileDivisions]) => {
        if (Array.isArray(profileDivisions)) {
          const profileDivisionsSet = new Set(profileDivisions);
          const legacyCompanyDivisionsSet = new Set(legacyCompanyDivisions);
          return profileDivisions.length === 0 || this._isSubset(profileDivisionsSet, legacyCompanyDivisionsSet);
        } else {
          return false;
        }
      })
    );
  }

    /**
   * Select current profile and check at least one division is LEGACY type
   *
   * @param companyId the currently selected company on the UI
   */
    private isLegacyDivisionsSome(companyId: string): Observable<boolean> {
      return combineLatest([this.getLegacyCompanyDivisionsByCompanyId(companyId), this.getProfileDivisions(companyId)]).pipe(
        map(([legacyCompanyDivisions, profileDivisions]) => {
          if (Array.isArray(profileDivisions)) {
            const profileDivisionsSet = new Set(profileDivisions);
            const legacyCompanyDivisionsSet = new Set(legacyCompanyDivisions);
          // return true if profileDivisionsSet has at least one division in it
            return profileDivisions.length > 0;
            // return true if at least one division in profileDivisionsSet is LEGACY type
            //return Array.from(profileDivisionsSet).some((division) => legacyCompanyDivisionsSet.has(division));
          } else {
            return false;
          }
        })
      );
    }

  private _isSubset(setA, setB) {
    for (let elem of setB) {
      if (!setA.has(elem)) {
        return false;
      }
    }
    return true;
  }

  public getProfileDivisions(companyId: string) {
    return combineLatest([
      this._permissionsService.getCompanyMap(),
      this._permissionsService.getUserProfiles(),
      this._permissionsService.getUserGroupPolicies()
    ]).pipe(
      filter(([companyMap, userProfiles, policies]) => !!(companyMap && userProfiles && policies)),
      take(1),
      map(([companyMap, userProfiles, userPolicies]): string[] => {
        const selectedCompany = companyMap[companyId] || {};
        if (selectedCompany.loginMode === ECompanyLoginMode.GROUP_POLICY) {
          const selectedPolicy = userPolicies.find(
            (userPolicy) =>
              userPolicy.policy.grants.find((grant) => grant.application.id === this.env.auth.applicationId) &&
              userPolicy.tenant.scope.companies.find((company) => company.id === companyId)
          );

          if (!selectedPolicy) {
            return [];
          }

          return selectedPolicy.tenant.scope.divisions.map((division) => division.id);
        } else {  
          const selectedProfiles = userProfiles.filter(
            (userProfile) =>
              userProfile.applicationId === this.env.auth.applicationId &&
              userProfile.companyId === companyId &&
              !!(userProfile.roles as IUserProfileRole[]).find(
                (role) =>
                  role.id === environment.companyAdminRole || role.id === environment.partialAdminRole
              ) &&
              userProfile.status === 'ACTIVE'
          );

          const allDivisionsFromUserProfilesSet = new Set<string>();

          selectedProfiles.map((profile) => {
            profile.divisions.forEach((division) => allDivisionsFromUserProfilesSet.add(division));
          });

          const allDivisionsFromUserProfilesArray = Array.from(allDivisionsFromUserProfilesSet);
          return allDivisionsFromUserProfilesArray;
        }
      })
    );
  }

  /**
   * Get the LEGACY divisions list from selected company
   *
   * @param companyId the currently selected company on the UI
   */
  private getLegacyCompanyDivisionsByCompanyId(companyId: string): Observable<string[]> {
    return this._permissionsService.getCompanyDivisions().pipe(
      filter((divisionMap) => !!divisionMap),
      map((divisionMap) => {
        return this.convertToLegacyDivisions(companyId, divisionMap);
      })
    );
  }

  /**
   * Logic filter legacy division
   *
   * @param companyId the currently selected company on the UI
   * @param divisionMap The all division that built into divisionId:divisionObject before
   */
  private convertToLegacyDivisions(companyId, divisionMap): string[] {
    const companyDivisions = (Object.values(divisionMap)[0] || []) as IDivision[];
    return companyDivisions.reduce((acc: string[], division: IDivision) => {
      if (division.companyId === companyId && division.type === 'LEGACY' && division.status === 'ACTIVE') {
        acc.push(division.id);
      }
      return acc;
    }, []) as string[];
  }

  private getLoginMode(
    isZonarUser: boolean,
    companyId: string,
    companyMap: ICompanyMap,
    policies: IUserGroupPolicy[]
  ): ECompanyLoginMode {
    if (isZonarUser) {
      return policies?.length ? ECompanyLoginMode.GROUP_POLICY : ECompanyLoginMode.USER_PROFILE;
    } else {
      const selectedCompany = companyMap[companyId] || {};
      return selectedCompany?.loginMode || ECompanyLoginMode.USER_PROFILE;
    }
  }

  private canAccessCompany(
    isZonarUser: boolean,
    loginMode: ECompanyLoginMode,
    companyId: string,
    userPolicy: IUserGroupPolicy,
    userProfile: IUserProfile
  ): boolean {
    const { adminRoleId } = this.env.thresholdSettingsBase;

    if (loginMode === ECompanyLoginMode.GROUP_POLICY) {
      if (isZonarUser) {
        return userPolicy?.tenant?.scope.type === ETenantType.ALL_COMPANIES;
      }
      const company = userPolicy?.tenant?.scope.companies.find(({ id }) => companyId === id);
      return !!(company && userPolicy?.policy.grants.find(grant => grant.roles.find(role => role.id === adminRoleId)));

    } else {
      if (isZonarUser) {
        return (userProfile.companyId === null && !userProfile.divisions.length);
      }
      return userProfile.companyId === companyId;
    }
  }

  private isProfileActive(profileOrPolicy) {
    return profileOrPolicy['status'] === 'ACTIVE';
  }

  private getThresholdAdminProfiles(companyId: string): Observable<boolean> {
    const { applicationId, adminRoleId } = this.env.thresholdSettingsBase;
    return combineLatest([
      this._permissionsService.getIsZonarUser(),
      this._permissionsService.getCompanyMap(),
      this._permissionsService.getUserProfiles(),
      this._permissionsService.getUserGroupPolicies(),
      this._permissionsService.getIsPermissionsLoaded()
    ]).pipe(
      filter(([_, companyMap, userProfiles, userPolicies, isLoaded]) => !!(companyMap && userProfiles && userPolicies && isLoaded)),
      take(1),
      map(([isZonarUser, companyMap, profiles, policies, _]) => {
        const loginMode = this.getLoginMode(isZonarUser, companyId, companyMap, policies);
        
        return loginMode === ECompanyLoginMode.GROUP_POLICY
          ? !!policies.find((userPolicy) => {
              return (
                userPolicy?.policy.grants.find((grant) => grant.application.id === applicationId) &&
                this.canAccessCompany(isZonarUser, loginMode, companyId, userPolicy, null) &&
                this.isProfileActive(userPolicy)
              );
            })
          : !!profiles.find((userProfile) => {
              return (
                userProfile.applicationId === applicationId &&
                this.canAccessCompany(isZonarUser, loginMode, companyId, null, userProfile) && 
                this.isProfileActive(userProfile) &&
                !!(userProfile.roles as IUserProfileRole[]).find((role) => role.id === adminRoleId)
              );
            });
      })
    );
  }
}
